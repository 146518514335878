import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/Layout'
import Testimonial from '../components/Testimonial'
import Container from '../components/Container'

export default () => {
	const data = useStaticQuery(query)

	return (
		<Layout>
			<Container>
				<Testimonial
					fluid={data.collette.childImageSharp.fluid}
					content={`
									"My father started my training when I was 5 years old. I continue learning today and I have never wanted to stop. 
									My father is extremely disciplined and strict which makes him a great teacher. He pushes you to achieve your fullest 
									potential both in the class and outside in the real world. He has taught me the importance of staying healthy and fit 
									unlike most people my age today and I'm happy that he was there to push me. Being in Wu Shu has helped me to stay in 
									shape and also learn how to protect myself if I ever have to. My dad has always been such a strong person and has 
									taught me that I can do what ever I put my mind to. I will continue to train with him and pass on my knowledge to any 
									new students who come along and have the pleasure to learn from my father."
								`}
					from="- Collette Aguirre, Assistant Coach"
				/>
				<Testimonial
					fluid={ data.hayleyMiller.childImageSharp.fluid }
					content={`
						"My exploration of movement modalities lead me to find Shifu Edward Aguirre and 
						Coach Uranmandakh Amarsanaa. I have spent a vast amount of time in Sports Acrobatics 
						where I was a highly developed competitor with specialties in tumbling, contortion, and partner 
						Acro and when choosing a teacher to learn from I have a high expectation of how a 
						professional atmosphere of such shall be conducted. With the opportunity to attend both 
						of these teachers classes I quickly discovered their expertise and passion for their work while 
						having the proper mentality to run a room filled with various age ranges and skill levels. My 
						time in class was an enjoyable and rewarding time. With a background in Mongolian 
						contortion and Wushu both teachers follow a disciplined format and both practices require one 
						to be focused and attentive while learning deep stretching, strength training, and dynamic trained drills 
						that are demanding of oneself. It is a patient practice to stick with but I recommend attending 
						a class if this is where your journey has brought you. Shifu Edward Aguirre and Coach 
						Uranmandakh Amarsanaa are a collaborative partnership that will bring forth your highest level 
						of skill in both Wushu and contortion."
					`}
					from="- Hayley Miller"
					url={{
						title: "Hayley Miller",
						url: "https://www.hayleymiller.com/"
					}}
				/>
				<Testimonial
					fluid={data.zhangYuping.childImageSharp.fluid}
					content={`
									"This letter is to confirm that Mr. Edward Aguirre is an experienced Wushu Coach who has been dedicated to Wushu training for
									over four decades. We first met in Xi'an back in 1985. Since then he's paid many visits to China for training, meeting with grand masters of
									Wushu. Our friendship has lasted for decades and he is always a true friend to me."
								`}
					from="- Zhang Yuping, Former China Women's All-around Champion"
				/>
				<Testimonial
					fluid={data.samirDosReisMacedo.childImageSharp.fluid}
					content={`
						I met Aguirre Shifu in 1996 at a modern Wushu seminar in the city of Ribeir'e3o Preto SP Brasil. I was enchanted by his technique, discipline, seriousness and professionalism. At the time playing with some colleagues I said: "I will become his disciple". Soon I started to study English in order to be able to communicate with him. After that, I contacted Shifu Aguirre in New York and told him about the problems I was facing in my country regarding martial arts. In the true sense of the word Shifu (master and father), he embraced my cause and readily accepted me as his student.

						In 1999 I managed to bring him to my city of Uberaba Minas Gerais to teach the 1st International seminar on Modern Wushu. The seminar was a success and many people were able to experience the practice of true Wushu from China. Exhaustive hours of training were very rewarding. We trained for 3 hours at the seminar and the rest of the day my Master was training me at the gym so that I could be able to promote this sport in my country. Other years passed and we held several seminars with the presence of Shifu Aguirre in our city. Since that time we have had a good master / disciple relationship, I have a lot of affection and respect for this wonderful human being and I really have him as a father and friend. I learned a lot from both martial and moral values and despite the distance this renowned Master is always present in my heart and in the heart of my family. Thank you very much Shifu Aguirre for the welcome, teachings and loyalty for all these years.
					`}
					from="- Samir dos Reis Macedo"
					url={{
						title: "Instituto Órion de Terapias Orientais",
						url: "https://institutoorionterapias.com.br/"
					}}
				/>
				<Testimonial
					fluid={ data.peterVallone.childImageSharp.fluid }
					content={`
						"This letter was written as a personal and unofficial reference dated January 11, 2008. Please let this letter serve as a character 
						reference for Edward Aguirre. I first met Edward Aguirre in 2002 when he came to my office to introduce himself and to seek council funding 
						for the Wu Shu martial art program that benefits children of our neighorhood and teaches them the values of hard work, dedication and discipline.
						The program has grown from just a few kids to over 40 participants. I have attended one of his practice sessions and observed him teach his class
						and saw that the students have a great deal of respect for him. He is highly energetic and dedicated person who is committed to his students."
					`}
					from="- Peter F. Vallone Jr., Former NYC Councilman 22nd District"
				/>
				<Testimonial
					fluid={ data.jamesKim.childImageSharp.fluid }
					content={`
						"I highly recommend Shifu Edward Aguirre as a great teacher, coach in the art of Wu Shu, and personally as a friend. His 
						technical skills and ability to teach are second to none, as he breaks down the most difficult movements for a novice to 
						challenging the most competitive students in the world. I was one of his students for over 4 years and even though my 
						background is in Tae Kwon Do, I found the movements dramatically different. I had to develop first its foundation. It is 
						challenging and very exciting, for me I was most impressed with its beautiful weapons as I have seen in the movies but 
						only now it is real to me. Wu Shu has opened many doors for me both in film and on television. I have been involved with 
						shows like; "Batman and Robin", "The Mortal Kombat Live Tour", "Walker, Texas Ranger", Exit Wounds" with Steven Segal and 
						most recent "Pacific Rim". If you want to learn from a true master with a no nonsense approach and the highest technical 
						skills in Wu Shu, I recommend Shifu Edward Aguirre."
					`}
					from="- Grandmaster James Kim, 7th Degree Black Belt - Tae Kwon Do"
				/>
				<Testimonial
					fluid={ data.carol.childImageSharp.fluid }
					content={`
						"Shifu Edward Aguirre has helped me gain core and leg strength through WuShu Martial Arts Program. 
						This unique program helped me gain self confidence in my movement. It was challenging, yet fulfilling 
						to be in class. After every lesson I learned a new way to jump kick, my legs were so sore the 
						next day and I loved it."
					`}
					from="- Carolyn Chiu"
					url={{
						title: 'Yoga Pole Studio',
						url: 'http://yogapolestudio.com/'
					}}
				/>
				<Testimonial
					fluid={ data.kwikstep.childImageSharp.fluid }
					content={`
						"I would like to take the time to honor Shifu Edward Aguirre by writing this testimonial. I cannot put into words
						how much of an inspiration he is to me, as we now know each other over a decade. Shifu offers endless knowledge 
						about the Wu Shu arts and always makes time for his students. He will be honest and upfront and will never lead 
						you to feel you are not important. He will dedicate himself fully to you and will expect no less from you when it 
						comes to your dedication in learning. He is centered, disciplined and loyal. Even though Shifu has a life challenging 
						spinal injury you would never know as he moves with grace across the room teaching with his whole mind, body and soul."
					`}
					from='- Gabriel "Kwikstep" Dionisio, Full Circle Productions, Executive & Artistic Director'
				/>
				{/* <Testimonial
					fluid={ data.howardBarnet.childImageSharp.fluid }
					content={`
						"I have known Edward Aguirre since 1987 and he is a wonderful human being, he would give you the shirt off his back if need be. 
						He is very honorable, respectful of others and the definition of class. I'm sure all his currenty and former students as well 
						as his friends and family hold him in very high regard as I do."
					`}
					from="- Howard Barnet"
				/> */}
				<Testimonial
					fluid={ data.jaredBravo.childImageSharp.fluid }
					content={`
						"I have to give a shout out to Shifu Edward Aguirre. This is an amazing wu shu artist. He trained my brother and he is world renowned. Absolutely a
						great person regardless of martial art abilities. If the rest of the world thought like him we wouldn't have any issues. I love him simply for who he is."
					`}
					from="- Jared, Bravo"
				/>
				<Testimonial
					fluid={ data.erickClaros.childImageSharp.fluid }
					content={`
						"Interesting enough, I'm not going to write about Grandmaster. I would rather share something more personal and what I
						feel, is the man behind the iron stare. In the few years I have known Shifu, I knew him more as a friend than a teacher.
						We spent most of our time joking around, lauging and enjoying our common bond as fathers. We have the honor and pleasure
						of having our daughters train under us and learn every little detail that we only share with them. This is the man I admire,
						I have great respect for Shifu but I really enjoy chatting and trying to guess his obsure television and movie references.
						It is great to be able to call Grandmaster Edward Aguirre my very good friend. I learned several things by watching him teach
						but I learned more from our friendly interactions. I'm glad to have met you."
					`}
					from="- Erick Claros, Brazilian Jiu-Jitsu Instructor"
				/>
				<Testimonial
					fluid={ data.armandoLeonChavez.childImageSharp.fluid }
					content={`
						"Estimado Shifu Edward Aguirre, "En México lo recordamos con mucho aprecio por todo el apoyo y la gran enseñanza que
						nos ha brindado a través de los seminarios que ha impartido en nuestro país. Cada visita ha representado para nosotros una
						excelente oportunidad de comprender la verdadera esencia del arte marcial chino. Como entrenadores estamos convencidos de
						que el nivel de su enseñanza es excepcional, es un honor e inspiración para nosotros y para todos los alumnos de nuestra organización
						en México, ser alumnos de Usted. Recordamos con nostalgia sus vistas a nuestro país, donde surgió una sincera amistad con nuestra 
						familia, la cual lo recuerda con gran cariño y aprecio. Esperamos que continúe cosechando éxitos con su labor de 
						promoción y difusión del Wu Shu. Nuestro mensaje a todos aquellos que aspiren a recibir una enseñanza de calidad con un gran maestro,
						cuya formación como entrenador ha sido con los mejores exponentes de Wu Shu es, que Usted es la mejor opción; ya que su entrega, 
						disciplina y vasto conocimiento de las Artes Marciales hacen que el ser humano alcance su mayor potencial físico, mental y espiritual."
					`}
					from="- Shifu Armando León y Marisela Hernández, Instituto Mexicano de Wushu y Muay Thai México"
				/>
			</Container>
		</Layout>
	)
}

const query = graphql`
	query {
		peterVallone: file(relativePath: { eq: "testimonials/peter_f_vallone_jr.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 766) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		howardBarnet: file(relativePath: { eq: "testimonials/howard_barnet.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 419) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		jaredBravo: file(relativePath: { eq: "testimonials/jared_bravo.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 319) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		erickClaros: file(relativePath: { eq: "testimonials/erick_claros.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 263) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		armandoLeonChavez: file(relativePath: { eq: "testimonials/armando_leon_chavez.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		jamesKim: file(relativePath: { eq: "index/james_kim.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 375) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		kwikstep: file(relativePath: { eq: "index/kwikstep.png" }) {
			childImageSharp {
				fluid(maxWidth: 520) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		carol: file(relativePath: { eq: "testimonials/carol.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2448) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		hayleyMiller: file(relativePath: { eq: "testimonials/hayley_miller.jpeg" }) {
			childImageSharp {
				fluid(maxWidth: 5358) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		collette: file(relativePath: { eq: "testimonials/collette_aguirre.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2792) {
				...GatsbyImageSharpFluid_noBase64
				}
			}
		}
		zhangYuping: file(relativePath: { eq: "testimonials/zhang_yuping.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 203) {
				...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		samirDosReisMacedo: file(relativePath: { eq: "testimonials/samir_dos_reis_macedo.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1944) {
				...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}
`
