import React from 'react'

import Img from 'gatsby-image'

import styled from 'styled-components'

import ShowMore from 'react-show-more'

export default ({ fluid, content, from, url }) => (
	<Container>
		<Portrait fluid={ fluid } />
		<div>
			<ContentContainter>
				<ShowMore lines={ 3 } anchorClass="show-more-anchor">
					{ content }
				</ShowMore>
			</ContentContainter>
			<FromText>
				{ from }
			</FromText>
			{ url && (
				<UrlContainer>
					<Url href={ url.url } target="_blank">
						{ url.title }
					</Url>
				</UrlContainer>
			) }
		</div>
	</Container>
)

const ContentContainter = styled.div`
	font-size: 1.6em;
	br {
		display: none;
	}
	span {
		margin-right: 4px;
	}
`
const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	padding-bottom: 80px;
	:last-child {
		padding-bottom: 0;
	}
	@media (max-width: 720px) {
		grid-template-columns: 1fr;
	}
`
const Portrait = styled(Img)`
	height: auto;
	width: 210px;
	align-self: center;
	@media (max-width: 720px) {
		margin: 0 auto;
		margin-bottom: 15px;
	}
`
const FromText = styled.div`
	text-align: right;
	font-size: 1.6em;
	margin-top: 20px;
	padding-right: 20px;
	color: #444;
	font-weight: 550;
	@media (max-width: 720px) {
		text-align: center;
		padding-right: 0;
	}
`

const UrlContainer = styled.div`
text-align: right;
`
const Url = styled.a`
	font-size: 1.6em;
	margin-top: 20px;
	padding-right: 20px;
	color: hsl(356,70%,53%);
	@media (max-width: 720px) {
		text-align: center;
		padding-right: 0;
	}
`
